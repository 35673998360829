import { useEffect, useCallback } from 'react';

function useClickedOutside(ref, callback, depsRaw) {

	const deps = Array.isArray(depsRaw) ? depsRaw : []

	//https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
	const clickHandler = useCallback((event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			callback(event.target);
		}
	}, [callback, ref, ...deps])

	useEffect(() => {
		document.addEventListener('mousedown', clickHandler)

		return () => document.removeEventListener('mousedown', clickHandler);
	}, [clickHandler])

}

export default useClickedOutside;
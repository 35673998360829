import { Icon } from '@iconify/react';
import colors, { shadows } from '../../styles/colors';
import styled from '@emotion/styled';
import { mediaBreakpoints, spacing, spacingS } from '../../styles/variables';
import { css } from '@emotion/react';
import { itemStyle } from './item.style';

const mediaBP = mediaBreakpoints.bp992;

export const SubMenuItem = (props) => {

	const Text = styled('span')([
		css`
			padding: ${spacingS} ${spacing};
			color: ${colors.textWhite};
			text-align: right;
			${props.isOpen && `
				background-color: ${colors.shade10};
			`}
		`,
		itemStyle
	]);

	const UL = styled('ul')`
		border-right: 4px solid ${colors.bgWhite};
		@media (min-width: ${mediaBP})  {
			border-right: none;
			position: absolute;
			background-color: ${colors.bgGreenDark};
			right: 0;
			min-width: 224px;
			${shadows.downLight}
		}
		`;

	const Li = styled('li')`
		position: relative;
	`;

	return (
		<Li onClick={props.onClick}>
			<Text>{props.title}&nbsp;&nbsp;&nbsp;<Icon
				icon={"bx:bxs-down-arrow"}
				height="12"
				color={colors.textWhite} />
			</Text>
			{props.isOpen &&
				<UL>
					{props.children}
				</UL>}
		</Li>
	);
};

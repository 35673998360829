import amplitude from 'amplitude-js';
import { isBrowser } from './environment-tools';

function init() {
	amplitude.getInstance().init(process.env.GATSBY_AMPLITUDE__API_KEY)
}

isBrowser() && init();

export const trackButtonClick = (name: string, data = {}) => {
	trackAmplitude(name, 'ButtonClick', data)
}

export const trackLinkClick = (name: string, data = {}) => {
	trackAmplitude(name, 'LinkClick', data)
}

export const trackEvent = (name: string, data = {}) => {
	trackAmplitude(name, 'Event', data)
}

function getEventNameAndProperties(name: string, postFix: string, data: {}) {
	const eventProperties = { ...data, context: "website", location: location.hostname };
	const eventName = name + postFix;
	return { eventName, eventProperties };
}



const trackAmplitude = (name: string, type: 'ButtonClick' | 'LinkClick' | 'Event', data = {}) => {
	const { eventName, eventProperties } = getEventNameAndProperties(name, `.${type}`, data);
	amplitude
		.getInstance()
		.logEvent(eventName, eventProperties);
}
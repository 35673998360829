import { useRef, useState } from 'react';
import colors, { shadows } from '../../styles/colors';
import AddToTrelloButton from '../add-to-trello-button';
import styled from '@emotion/styled';
import { mediaBreakpoints } from '../../styles/variables';
import useMediaQuery from '../../hooks/use-media-query';
import useClickedOutside from '../../hooks/use-click-outside';
import { SubMenuItem } from './sub-menu-item';
import { LinkItem } from './link-item';
import HamburgerMenu from './hamburger-menu';

const mediaBP = mediaBreakpoints.bp992;

const Container = styled('div')`
	position: relative;
	z-index: 1;
	display: flex;
`

const NavContainer = styled('div')`
	position: absolute;
	background-color: ${colors.bgGreenDark};
	right: 0;
	top: 40px;
	padding: 8px 8px 8px;
	width: 240px;
	${shadows.down}
	@media (min-width: ${mediaBP})  {
		top: auto;
		background-color: transparent;
		position: relative;
		padding: 0;
		width: auto;
		box-shadow: none;
	}
`

const Nav = styled('nav')`
	display: flex;
	flex-direction: column;
	@media (min-width: ${mediaBP})  {
		flex-direction: row;
	}
`;

const UL = styled('ul')`
	display: flex;
	flex-direction: column;
	@media (min-width: ${mediaBP})  {
		flex-direction: row;
	}
`;


const Navbar = () => {

	type ISubmenuOpened = null | 'policies' | 'guides';

	const [isOpen, setIsOpen] = useState(false)
	const [submenuOpened, setSubmenuOpened] = useState<ISubmenuOpened>(null)
	const isDesktop = useMediaQuery({ minWidth: mediaBP })
	const navRef = useRef(null)
	useClickedOutside(navRef, () => { setIsOpen(false); setSubmenuOpened(null) }, [])

	const handleSubmenuClick = (target: ISubmenuOpened) => {
		if (submenuOpened == target) {
			setSubmenuOpened(null)
		}
		else {
			setSubmenuOpened(target)
		}
	}



	return (
		<Container>
			<HamburgerMenu isUsed={isDesktop} isOpen={isOpen} onClick={() => setIsOpen(prev => !prev)} />
			{(isOpen || isDesktop) &&
				<NavContainer>
					<Nav ref={ref => navRef.current = ref}>
						<UL >
							<LinkItem to="/">Home</LinkItem>
							<LinkItem to="/pricing">Prices</LinkItem>
							<SubMenuItem
								isOpen={submenuOpened == 'guides'}
								title="Guides"
								onClick={() => handleSubmenuClick('guides')}
							>
								<LinkItem to="/reauthorization-guide">Re-Authorization</LinkItem>
								<LinkItem to="/enabling-cookies-guide">Enabling cookies</LinkItem>
								<LinkItem to="/update-payment-guide">Update payment method</LinkItem>
							</SubMenuItem>
							<SubMenuItem
								isOpen={submenuOpened == 'policies'}
								title="Policies"
								onClick={() => handleSubmenuClick('policies')}
							>
								<LinkItem to="/privacy-policy">Privacy policy</LinkItem>
								<LinkItem to="/terms-of-use">Terms of Use</LinkItem>
							</SubMenuItem>
						</UL>
						< AddToTrelloButton parent='navbar' />
					</Nav>
				</NavContainer>
			}
		</Container>
	);
};

export default Navbar;
import * as React from 'react'
import { Global, css } from "@emotion/react"
import styled from "@emotion/styled"
import { StaticImage } from 'gatsby-plugin-image';
import { Link, useStaticQuery, graphql } from 'gatsby'
import globalStyles from '../styles/global-styles';
import colors from '../styles/colors';
import SingleBGArea from './single-bg-area';
import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {

	// 	const data = useStaticQuery(graphql`
	// 	query {
	// 		site {
	// 			siteMetadata {
	// 				title
	// 			}
	// 		}
	// 	}
	// `)

	const Wrapper = styled('div')`
	position: relative;
	min-height: 100vh;
	`



	return (
		<Wrapper>
			<Global
				styles={globalStyles}
			/>
			<Header />
			<main>
				{/* <h1 className={heading}>{pageTitle}</h1> */}
				{children}
			</main>
			<Footer />
		</Wrapper>
	)
}

export default Layout
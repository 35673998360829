import { css } from "@emotion/react";
import colors from "./colors";
import cssReset from "./css-reset";
import { textStyles } from "./texts";
import { fontSize, lineHeight, spacing } from "./variables";

export default css`
	${cssReset}
	
	* {
		font-family: Roboto, Arial, sans-serif;
		${textStyles.lRegular}
		box-sizing: border-box;
	}

	body {

	}

	h1, h2, h3, h4, h5, h6 {
		margin-bottom: ${spacing};
	}

	em {
		font-style: italic;
	}

	/* p {

	} */
`;
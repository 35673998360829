import { Link } from 'gatsby';
import { itemStyle } from './item.style';

export const LinkItem = (props) => {

	return (
		<li>
			<Link css={[itemStyle]} to={props.to}>
				{props.children}
			</Link>
		</li>
	);
};

const rhythmMultiplier = 1.5;
const gridBaseUnit = 8;
const baseFontSize = gridBaseUnit * 2;
export const rhythm = baseFontSize * rhythmMultiplier;
export const fontSize = baseFontSize + 'px';
export const lineHeight = rhythm + 'px';
export const gutter = rhythm + 'px';
export const spacingS = rhythm * 0.5 + 'px';
export const spacing = rhythm + 'px';
export const spacingL = rhythm * 2 + 'px';
export const spacingXL = rhythm * 3 + 'px';
export const spacing2XL = rhythm * 4 + 'px';
export const spacing3XL = rhythm * 5 + 'px';
export const spacing4XL = rhythm * 6 + 'px';

export const innerSpacing = gridBaseUnit + 'px';
export const innerSpacingL = gridBaseUnit * 2 + 'px';
export const innerSpacingXL = gridBaseUnit * 4 + 'px';

export const mediaBreakpoints = { bp992: '992px' }

export const maxContentFrameWidthForLandingPage = '1128px';
export const maxContentFrameWidthForPage = '800px';
// export const mediaQuery = { min992px: () => `@media (min-width: ${992}px)` }

// export const fontSizes = {
// 	xxl: 3 * baseFontSize + 'px',
// 	xl: 3 * baseFontSize + 'px',
// }

// export const lineHeights = {
// 	l: 1.75 + 'em',
// 	m: 1.5 + 'em',
// 	s: 1.25 + 'em',
// }




import * as React from 'react';
import Button from './button';
import TrelloLogo from '../images/trello-logo--white.svg';
import styled from '@emotion/styled';
import colors from '../styles/colors';
import { trackLinkClick } from '../tracking';

const AddButton = styled(Button)`
	background: ${colors.brandSecondary};
	color: ${colors.textWhite};
	a {
		text-decoration: none;
		color: ${colors.textWhite};
	}
`;

const Logo = styled(TrelloLogo)`
	height: 14px; 
	width: auto;
`;

type IProps = {
	className?: string;
	parent: string;
}


const AddToTrelloButton = (props: IProps) => {
	return (
		<AddButton className={props.className}>
			<a
				href='https://trello.com/power-ups/5e4464985fd190825d164de3/enable'
				target='_blank'
				onClick={() => trackLinkClick('AddToTrello', { placement: props.parent })}
			>
				Add to&nbsp;&nbsp;<Logo />
			</a>
		</AddButton>
	);
};

export default AddToTrelloButton;
import { Icon } from "@iconify/react"
import { Fragment } from "react"
import colors from "../../styles/colors"
import HamburgerClosed from '../../images/hamburger-closed.svg';
import HamburgerOpened from '../../images/hamburger-opened.svg';

const HamburgerMenu = props => {
	return (
		<Fragment>
			{
				props.isUsed ? null : props.isOpen ?
					// <Icon
					// 	icon={"ci:close-big"}
					// 	height="48" color={colors.textWhite}
					// 	onClick={props.onClick}
					// />
					<HamburgerOpened
						onClick={props.onClick}
					/>
					:
					<HamburgerClosed
						onClick={props.onClick}
					/>
				// <Icon
				// 	icon={"ci:hamburger"}
				// 	height="48" color={colors.textWhite}
				// 	onClick={props.onClick}
				// />
			}
		</Fragment>
	)
}

export default HamburgerMenu
import styled from '@emotion/styled';
import * as React from 'react';
import { shadows } from '../styles/colors';
import { innerSpacingL, innerSpacing, rhythm } from '../styles/variables';

const Button = styled('button')`
	height: ${rhythm * 2}px;
	max-width: 240px;
	min-width: 160px;
	width: 100%;
	padding: ${innerSpacing} ${innerSpacingL};
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	border: none;
	cursor: pointer;
	${shadows.down}
	&:active {
		box-shadow: none;
	}
`

export default Button;
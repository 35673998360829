import { useEffect, useState } from "react";
import { isBrowser } from "../environment-tools";

const useMediaQuery = (query: { minWidth: string }) => {

	const [matches, setMatches] = useState(windowMatch().matches);
	useEffect(() => {
		const handler = e => setMatches(e.matches);
		windowMatch().addEventListener('change', handler);
		return () => windowMatch().removeEventListener('change', handler);
	}, [])

	function windowMatch() {
		return isBrowser() && window.matchMedia(`(min-width: ${query.minWidth})`)
	}

	return matches;
}

export default useMediaQuery;
import colors from '../../styles/colors';
import { spacing, spacingS } from '../../styles/variables';
import { css } from '@emotion/react';

// const mediaBP = '1px';
export const itemStyle = css`
	padding: ${spacingS} ${spacing};
	display: flex;
	justify-content: end;
	align-items: center;
	text-align: right;
	/* width: 144px; */
	a& {	
		color: ${colors.textWhite};
		text-decoration: none;
	}
	:hover{
		background-color: ${colors.tint10};
	}
	:active{
		background-color: ${colors.shade10};
	}
`;

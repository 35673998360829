import styled from '@emotion/styled';
import * as React from 'react';
import colors from '../styles/colors';
import { textStyles } from '../styles/texts';
import SingleBGArea from './single-bg-area';

const Container = styled(SingleBGArea)`
  padding: 0;
`;

const Wrapper = styled('footer')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Text = styled('span')`
  color: ${colors.textSecondary};
  ${textStyles.sMedium}
  width: 100%;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container bgColor={colors.bgGray}>
        <Text>©Copyright Bulk Actions Power-Up 2022. All rights reserved</Text>
      </Container>
    </Wrapper>
  );
};

export default Footer;

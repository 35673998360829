import styled from '@emotion/styled';
import * as React from 'react'
import colors, { shadows } from '../styles/colors';
import BALogo from '../images/ba-logo--default.svg';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import Navbar from './navbar';
import SingleBGArea from './single-bg-area';

const Container = styled('div')`
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`

const LogoText = styled('span')`
	/* font-size: 16px; */
	line-height: 1em;
	color: ${colors.textWhite};
	font-weight: 500;
	display: inline-flex;
`

const BALogoContainer = styled('div')`
	display: flex;
	cursor: pointer;
	align-items: center;
`;

const Wrapper = styled(SingleBGArea)`
	padding: 0;
	position: sticky;
	top: 0;
	z-index: 1;
	${shadows.downLight}
`

const Header = () => {
	return (
		<Wrapper bgColor={colors.brandPrimary}>
			<Container>
				<Link to="/" css={css`text-decoration: none;`}>
					<BALogoContainer>
						<BALogo css={css`height: 32px; width: auto; margin-right: 8px;`} />
						<LogoText>Bulk Actions<br />Power-Up</LogoText>
					</BALogoContainer>
				</Link>
				<Navbar />
			</Container >
		</Wrapper>
	);
};

export default Header;
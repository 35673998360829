import * as React from 'react'
import styled from "@emotion/styled"

import { gutter, maxContentFrameWidthForLandingPage as maxContentFrameWidthForLandingPage, spacingXL } from '../styles/variables';

type IProps = {
	bgColor: string;
	children: React.ReactNode;
	className?: string;
}

const SingleBGArea = (props: IProps) => {

	const children = props.children;
	const bgColor = props.bgColor;

	const Wrapper = styled('div')`
		width: 100%;
		background: ${bgColor};
		display: flex;
		justify-content: center;
		padding: ${spacingXL} 0;
	`;

	const Container = styled('div')`
		/* height: 5em; */
		max-width: ${maxContentFrameWidthForLandingPage};
		align-items: center;
    display: flex;
		width: 100%;
		padding: 0 ${gutter};
		flex-direction: column;
		user-select: none;
	`

	return (
		<Wrapper className={props.className}>
			<Container>
				{children}
			</Container>
		</Wrapper>
	)
}

export default SingleBGArea
import { css } from "@emotion/react";

const standardFont = css`
	font-family: Roboto, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
`;

const textsRhythm = {
	xxxxl: css`
		font-size: 40px; 
		line-height: 1.25em;
	`,
	xxxl: css`
		font-size: 36px;
		line-height: 1.25em;
	`,
	xxl: css` //not used yet
		font-size: 24px;
		line-height: 1.5em;
	`,
	xl: css`
		font-size: 20px;
		line-height: 1.5em;
	`,
	l: css`
		font-size: 16px;
		line-height: 1.5em;
	`,
	m: css`
		font-size: 14px;
		line-height: 1.5em;
	`,
	s: css`
		font-size: 12px;
		line-height: 1.5em;
	`,
}


export const textStyles = {
	xxxxlRegular: css`
		${standardFont}
		${textsRhythm.xxxxl}
		font-weight: normal;
	`,
	xxxlMedium: css`
		${standardFont}
		${textsRhythm.xxxl}
	`,
	xxlMedium: css`
		${standardFont}
		${textsRhythm.xxl}
	`,
	xlMedium: css`
		${standardFont}
		${textsRhythm.xl}
	`,
	// lMediumUnderlined: css`
	// 	${standardFont}
	// 	${textsRhythm.l}
	// 	font-weight: normal;
	// 	`,
	lMedium: css`
		${standardFont}
		${textsRhythm.l}
		`,
	lRegular: css`
		${standardFont}
		${textsRhythm.l}
		font-weight: normal;
		`,
	sMedium: css`
		${standardFont}
		${textsRhythm.s}
		/* font-weight: bold; */
	`,
	xsRegular: css`
		${standardFont}
		font-size: 10px;
		line-height: 1.5em;
		/* font-weight: bold; */
	`,
}

// Inspired by Github
export const contentTextStyles = {
	h1: css`
		${standardFont}
		font-size: 2em;
		line-height: 1.25;
		font-weight: 600;
	`,
	h2: css`
		${standardFont}
		font-size: 1.5em;
		line-height: 1.25;
		font-weight: 500;
	`,
	h3: css`
		${standardFont}
		font-size: 1.25em;
		line-height: 1.25;
		font-weight: 500;
	`,
	h4: css`
		${standardFont}
		font-size: 1em;
		line-height: 1.25;
		font-weight: 500;
	`,
	paragrpah: css`
		${standardFont}
		font-size: 16px;
		line-height: 1.5;
		font-weight: 400;
	`,
}
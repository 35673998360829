
const gray = '#F2F2F2';
const grayDark = '#899AA3';
const green = '#61BD4F';
const green20 = "#DFF2DC";
const greenDark = '#3F6F21';
const blue = '#298FCA';
const blue20 = '#d4e9f4';
const blueDark = '#055A8C';
const white = '#ffffff';
const white10 = withAlpha(white, 0.1);
const black = '#172B4D';
const black10 = withAlpha(black, 0.1);

export const gradients = {
	green: `linear-gradient(180deg, ${green} 0%, ${withAlpha(green, 0.8)} 50%, ${withAlpha(green, 0.2)} 100%)`
}

export const shadows = {
	down: `box-shadow: 4px 4px 4px ${withAlpha(black, 0.25)};`,
	downLight: `box-shadow: 4px 4px 4px ${withAlpha(black, 0.10)};`,
	uniform: `box-shadow: 0px 0px 24px 4px ${withAlpha(black, 0.25)};`
}

export default {
	brandPrimary: green,
	brandPrimaryLight: green20,
	brandSecondary: blue,
	textPrimary: greenDark,
	textSecondary: grayDark,
	textWhite: white,
	textLink: blue,
	textBlack: black,
	bgWhite: white,
	bgGray: gray,
	bgGreenDark: greenDark,
	shade10: black10,
	tint10: white10,
	blueLight: blue20,
	blueDark: blueDark,
}

function withAlpha(hexColor: string, alpha: number) {
	return hexColor + Math.round(255 * alpha).toString(16);
}